<template>
  <div
    ref="spot"
    class="product-spot p-24 sm:p-32 mb-40 bg-grey400 flex flex-col justify-between"
  >
    <div v-if="product" class="flex flex-col h-full">
      <nuxt-link :to="productUrl" @click.native="productClick">
        <div
          class="max-h-240 max-w-320 h-full w-full flex justify-center items-center mx-auto relative"
        >
          <img
            v-if="
              productImage.shouldShowShadow &&
              productImage.image &&
              !isBundleProduct &&
              !fullWidthImage &&
              product.showCanShadow &&
              !gifImage
            "
            src="~/assets/images/can-shadow.png"
            class="can-image"
            height="240"
            width="320"
          />
          <nuxt-img
            :src="gifImage ? gifImage[0]?.url : productImage.image"
            class="product-image max-h-240 max-w-240 z-10 object-contain"
            height="360"
            width="360"
            :alt="product.name"
            :preset="gifImage ? '' : 'productImage'"
            :provider="gifImage ? '' : 'norce'"
            loading="lazy"
          />
          <nuxt-img
            v-if="pouchImage && productImage.image && !fullWidthImage && !gifImage"
            preset="standard"
            loading="lazy"
            :src="pouchImage"
            class="pouch-image"
          />
        </div>
      </nuxt-link>
      <nuxt-link :to="productUrl" @click.native="productClick">
        <div class="base-medium-text mt-24">{{ product.name }}</div>
      </nuxt-link>
      <nuxt-link :to="productUrl" @click.native="productClick">
        <div v-if="product.description" class="base-text text-grey600 mt-4">
          {{ product.description }}
        </div>
      </nuxt-link>
      <ClientOnly v-if="purchaseEnabled" class="min-h-[50px]">
        <div class="py-24 mt-auto">
          <div v-if="product.variants.length > 1">
            <div class="base-medium-text mb-8">
              {{ $lang('productListingResources', 'variantHeading') }}
            </div>
            <div class="flex">
              <div
                v-for="variant in product.variants"
                :key="variant.partNo"
                class="w-40 h-40 flex justify-center items-center mr-8 last:mr-0 base-medium-text select-none cursor-pointer rounded-sm"
                :class="{
                  'bg-grey200': variant.partNo !== selectedVariantId,
                  'bg-grey200 border border-black':
                    variant.partNo === selectedVariantId,
                }"
                @click="selectVariant(variant.partNo)"
              >
                {{ variant.packageSize }}x
              </div>
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div v-if="selectedVariant && purchaseEnabled">
              <div
                class="preamble-text mt-12"
                :class="{ 'text-error': selectedVariant.hasDiscount }"
              >
                {{ selectedVariant.price }}
                <span
                  v-if="
                    selectedVariant.hasDiscount && selectedVariant.recommendedPrice
                  "
                  class="base-text text-grey600 line-through font-normal"
                  >({{ selectedVariant.recommendedPrice }})</span
                >
              </div>
              <div
                v-if="showPricePerCan"
                class="base-medium-text text-grey600 font-normal"
              >
                {{
                  `(${selectedVariant.pricePerCan}${$lang(
                    'productListingResources',
                    'perCanSuffix'
                  )})`
                }}
              </div>
            </div>
            <div v-if="!inStock" class="self-end flex items-center">
              <div class="h-8 w-8 rounded-full bg-error mr-8 font-medium" />
              {{ $lang('sharedResources', 'outOfStock') }}
            </div>
          </div>
        </div>
      </ClientOnly>
    </div>
    <ClientOnly v-if="purchaseEnabled" class="min-h-[50px]">
      <Button
        v-if="purchaseEnabled && inStock"
        :icon="subscription ? 'refresh' : 'shopping-bag'"
        :is-block="true"
        :size="Sizes.md"
        :text="actionText"
        :disabled="!inStock"
        :loading="isLoading"
        class="flex-shrink-0"
        @click="addItemToCart"
      />
      <nuxt-link v-if="purchaseEnabled && !inStock"
        :to="productUrl" @click.native="productClick"
        class="btn btn--md btn--dark-ghost-theme btn--wide mt-12"
      >
        <span class="base-medium-text">{{ $lang('productListingResources', 'getNotified') }}</span>
      </nuxt-link>
    </ClientOnly>
    <div v-if="product?.flags && product?.flags.length > 0" class="absolute top-24 left-24 z-20 flex items-start">
      <div
        v-for="flag in product.flags"
        :key="`productSpotFlag-${flag.name}-${product.id}`"
        class="px-12 py-8 mr-12 last:mr-0 mb-12 text-grey200 font-medium text-sm uppercase w-fit-content"
        :style="`background-color: ${
          flag.code !== 'thunder-new' ? flag.color : 'transparent'
        }`"
      >
        <img
          v-if="flag.code === 'thunder-new'"
          class="h-32"
          src="~/assets/images/thunder-new-flag.png"
        />
        <nuxt-link v-else-if="flag.urlSegment"
          :to="flag.urlSegment"
        >
          {{ flag.name }}
        </nuxt-link>
        <span v-else>{{ flag.name }}</span>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { useCartStore } from '~/store/cart';
import Button from './globals/Button.vue';
import { Sizes } from '~/constants/button-layouts';
import {
  type IProductItem,
  type IProductVariant,
  PurchaseType,
} from '~/models/api-types';
import { usePageContentStore } from '~/store/pageContent';
import * as Sentry from '@sentry/vue'
import { useVoyadoStore } from '~/store/voyado';

const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const cartStore = useCartStore();
const { $lang } = useNuxtApp();
const route = useRoute();
const pageStore = usePageContentStore();
const voyado = useVoyadoStore();

interface IProps {
  product: IProductItem,
  isMobile?: boolean,
  useSchemaProps?: boolean,
  subscription?: boolean,
  listName?: string,
  index?: number,
  fullWidthImage?: Boolean,
}

const props = withDefaults(defineProps<IProps>(), {
  useSchemaProps: true
});

const index = ref(props.index ? props.index : 1);
const selectedVariantId = ref('');
const isLoading = ref(false);
const spot = ref();
const productUrl = ref('');

const selectedVariant = computed(() => {
  return (
    props.product.variants.find(
      (variant) => variant.partNo === selectedVariantId.value
    ) || null
  );
});

const pouchImage = computed(() => {
  return props.product.pouchImageUrl;
});

const gifImage = computed(() => {
  if (!config.currentMarket?.showGifImagesOnProductListingPage) return false;

  const gifImage = props.product.additionalImages?.filter((image) => {
    return image?.code == 'gif';
  });

  if (gifImage.length) return gifImage;

  return false;
});

const productImage = computed((): { image: string; shouldShowShadow: boolean } => {
  const variantIndex = props.product.variants
    .map((variant) => variant.partNo)
    .indexOf(selectedVariantId.value);

  return selectedVariant.value?.imageUrl
    ? {
        image: `${selectedVariant.value?.imageUrl}?w=360&h=360`,
        shouldShowShadow: variantIndex <= 0 && props.product.showCanShadow,
      }
    : {
        image: `${props.product.imageUrl}?w=360&h=360`,
        shouldShowShadow: true,
      };
});

const inStock = computed(() => {
  return !!selectedVariant.value?.inStock;
});

const actionText = computed(() => {
  return inStock.value
    ? $lang(
        'productListingResources',
        props.subscription ? 'subscribeButton' : 'purchaseButton'
      )
    : $lang('productListingResources', 'outOfStock');
});

const isBundleProduct = computed(() => {
  return (
    selectedVariant.value?.productType === 'ManagedStructure' ||
    selectedVariant.value?.productType === 'ManagedErpPPackage' ||
    selectedVariant.value?.productType === 'ManagedErpPackageWithCalc'
  );
});

const purchaseEnabled = computed(() => {
  return !!config.currentMarket?.enablePurchases;
});

const showPricePerCan = computed(() => {
  if (!isBundleProduct.value && props.product.variants.length < 2) {
        return false;
      } else if (!isBundleProduct.value) {
        return true;
      } else if (
        selectedVariant.value &&
        selectedVariant.value.pricePerCan !== selectedVariant.value.price
      ) {
        return true;
      }
      return false;
});

onBeforeMount(() => {
  const numberOfVariants = props.product.variants.length;
  let preferredVariant = props.product.variants.find(variant => variant.isPreferredVariant);
  productUrl.value = props.product.url!;
  
  if (preferredVariant && preferredVariant.inStock) {
    selectedVariantId.value = preferredVariant?.partNo;
  } else if (
    numberOfVariants > props.product.standardProductVariant - 1 &&
    props.product.variants[props.product.standardProductVariant - 1].inStock
  ) {
    selectedVariantId.value =
      props.product.variants[props.product.standardProductVariant - 1].partNo;
  } else {
    let currentIndex = numberOfVariants - 1;
    let foundStandard = false;
    productUrl.value = props.product.url!;
    do {
      if (currentIndex === 0) {
        selectedVariantId.value = props.product.variants[currentIndex].partNo;
        foundStandard = true;
      } else if (props.product.variants[currentIndex].inStock) {
        selectedVariantId.value = props.product.variants[currentIndex].partNo;
        foundStandard = true;
      } else {
        currentIndex--;
      }
    } while (!foundStandard);
  }
});

onMounted (() => {
  nextTick(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            window.dataLayer?.push({
              event: 'productImpressions',
              ecommerce: {
                currencyCode: selectedVariant.value?.currencyCode,
                impressions: [
                  {
                    list: props.listName ?? route.path,
                    ...selectedVariant.value?.gtmProperties,
                    variant: '1-pack',
                  },
                ],
              },
            });
            if (selectedVariant.value) {
              voyado.sendProductView(selectedVariant.value);
            }
            if (spot.value) {
              observer.unobserve(spot.value as Element);
            }
          }
        });
      },
      { rootMargin: '0px', threshold: 0.5, root: null }
    );
    if (spot.value) {
      observer.observe(spot.value as Element);
    }
  });
});

const productClick = () => {
  window.dataLayer?.push({
    event: 'productClick',
    ecommerce: {
      currencyCode: selectedVariant.value?.currencyCode,
      click: {
        actionField: {
          list: props.listName ?? route.path,
        },
        products: [
          {
            position: props.index,
            ...selectedVariant.value?.gtmProperties,
            variant: '1-pack',
          },
        ],
      },
    },
  });
};

const selectVariant = (id: string) => {
  selectedVariantId.value = id;
  window.sessionStorage.removeItem(selectedVariant.value?.gtmProperties.id + '-packageSize');
  window.sessionStorage.setItem(selectedVariant.value?.gtmProperties.id + '-packageSize', selectedVariant.value?.packageSize);
};

const addItemToCart = async() => {
  if (selectedVariant.value) {
    try {
      isLoading.value = true;
      await cartStore.addItemToCart({
        item: selectedVariant.value,
        purchaseType: props.subscription
          ? PurchaseType.Subscription
          : PurchaseType.Standard,
        hidePopup: false,
      });
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      isLoading.value = false;
    }
  }
};

const isProductPage = computed(() => {
  return pageStore.pageType === 'ProductPage';
});

const product = props.product as IProductItem | null;
const selectedVariantValue = selectedVariant.value as IProductVariant | null;
const host = globalContentStore.host;

const jsonLd = {
  '@context': 'https://schema.org',
  '@type': 'Product',
  gtin: product?.ean,
  name: product?.name,
  description: product?.description,
  sku: selectedVariantValue?.gtmProperties.id,
  image: product?.imageUrl,
  brand: {
    '@type': 'Brand',
    name: selectedVariantValue?.gtmProperties.brand,
  },
  url: host + (product?.url ?? ''),
  offers: {
    '@type': 'Offer',
    priceCurrency: selectedVariantValue?.currencyCode,
    price: selectedVariantValue?.gtmProperties?.price
      ?.toString()
      ?.replace(',', '.'),
    availability: selectedVariantValue?.inStock
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
  },
} as any;

if (props.useSchemaProps && !isProductPage.value) {
  useJsonld(jsonLd);
}
</script>
<style>
.product-spot {
  position: relative;
  width: 100%;
  @screen md {
    width: calc(33.333% - 16px);
  }

  &--slider {
    @apply mb-0 sm:mb-40;

    width: calc(100% - 16px);
  }
}

.product-spot:not(:nth-child(3n)) {
  @screen md {
    margin-right: 24px;
  }
}

.product-spot--slider:not(:nth-child(3n)) {
  @apply md:mr-0;
}

.pouch-image {
  bottom: -41px;
  @apply max-h-112 absolute right-0 z-20;
}

.can-image {
  position: absolute;
  bottom: -33px;
  right: 0;
  left: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
}
</style>
